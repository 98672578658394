import MicroModal from 'micromodal';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const formsWrapper = document.querySelector('.hop-fyh__forms-wrapper');
const modal = () => {
    MicroModal.init({
        onShow: modal => {
            if (!formsWrapper) return;
            const form = formsWrapper.querySelector(`#gform_wrapper_${modal.dataset.form}`);
            modal.querySelector('.hop-modal-form__form').append(form);
            modal.querySelector('.hop-modal__wrapper').scrollTop = 0;
        },
        onClose: modal => {
            if (!formsWrapper) return;
            const form = modal.querySelector(`#gform_wrapper_${modal.dataset.form}`);
            formsWrapper.append(form);
        },
        disableScroll: true,
    });

    const teamSliders = document.querySelectorAll('.hop-modal__gallery .swiper');

    if (teamSliders.length > 0) {
        teamSliders.forEach(slider => {
            const sliderWrapper = slider.closest('.hop-modal__gallery');
            const sliderInit = () => {
                new Swiper(slider, {
                    slidesPerView: 1,
                    spaceBetween: 30,
                    navigation: {
                        nextEl: sliderWrapper.querySelector('.hop-slider-navigation__btn.btn-next'),
                        prevEl: sliderWrapper.querySelector('.hop-slider-navigation__btn.btn-prev'),
                    },
                })
            }
            sliderInit();
        })
    }
};

export default modal;
